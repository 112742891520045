import React from "react";
import './projects.css';

function Projects() {

    return (
        <div className="projects">
            <div className="projectsHeader">
                    <h1>Projects</h1>
                    <hr></hr>
            </div>
        </div>
    );
}

export default Projects;